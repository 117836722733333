<!-- 道路测速 -- 主页入口文件 -->
<template>
  <div id="velocityMeasurement-index">
    <transition name="slide-fade" mode="out-in">
        <keep-alive :include="include">
            <router-view></router-view>
        </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
    data(){
        return{
            include: []
        }
    }
}
</script>

<style scoped>
#velocityMeasurement-index{
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    border-radius: 3px;
}
.slide-fade-enter-active {
    transition: all 0.8s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>